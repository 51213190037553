<template>
  <div class="d-flex flex-column overflow-y-hidden full-height">
    <v-text-field
      class="flex-grow-0 border-radius pb-3"
      :prepend-inner-icon="$icon('i.Search')"
      :label="$t('t.Search')"
      clearable
      flat
      hide-details
      solo
      v-model="search.searchText"
    />
    <transition name="fade-transition">
      <v-sheet
        v-show="!loading"
        class="border-radius overflow-y-auto overflow-x-hidden"
      >
        <suggestion-list
          v-if="search.items.length || loading"
          class="full-height"
          :items="search.items"
          show-detail
          ref="list"
          @end-reached="search.retrieveNextPage()"
          width="100%"
        >
          <template v-slot:default="{ item }">
            <component
              :is="searchItemType"
              :item="item"
              :search="search"
            />
          </template>
        </suggestion-list>
        <div
          v-else
          class="d-flex flex-column align-center"
        >
          <v-img
            class="my-4 flex-shrink-1"
            contain
            max-width="50%"
            src="@/assets/no-results.svg"
          />
          <div class="text-h4 mb-4">
            {{$t("t.NoResult")}}
          </div>
        </div>
      </v-sheet>
    </transition>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  name: 'search',
  components: {
    SearchItemAccounts: () => import('@/pages/mobility/components/search-item-accounts'),
    SearchItemInvoices: () => import('@/pages/mobility/components/search-item-invoices'),
    SuggestionList: () => import('@/pages/search/components/suggestion-list')
  },
  computed: {
    columnSets () {
      return {
        accounts: [
          { col: '41a879e9-4a8a-4836-89fe-9825a61e1e4e' }, // Business identifer
          { col: '81c35e89-70b3-4929-a6cf-9777593f36bb' }, // Société
          { col: '3fc20a9c-15c0-460e-b830-813a0ca1a895', currency: 'account' } // En cours risque DC
        ],
        invoices: [
          { col: '460fc952-d2e3-40cf-bdaf-13da5503d8fb' }, // Invoice number
          { col: '07deca16-b4a1-4a9f-94e7-d44e499bb288', currency: 'invoice' }, // Balance DF
          { col: '63356a13-9676-4cee-9647-18fac81cedfd' }, // Date d'échéance
          { col: '65d60f17-7713-4ef0-945f-69380fa9df0f' }, // Document links
          { col: 'a5356e63-5dec-4178-bc4f-b1cbd4f8aad1' } // Smart status
        ]
      }
    },
    sortSets () {
      return {
        accounts: {
          sortBy: [{ col: '3fc20a9c-15c0-460e-b830-813a0ca1a895' }], // En cours risque DC
          sortDesc: [true]
        },
        invoices: {
          sortBy: [
            { col: '10fad800-d9ec-4050-a466-cf5eae84e4bf' }, // Open
            { col: '233536a6-ac8e-4159-8061-d60066cd4bdd' }, // Paid date
            { col: '63356a13-9676-4cee-9647-18fac81cedfd' } // Due date
          ],
          sortDesc: [true, true, false]
        }
      }
    },
    searchItemType () {
      return `search-item-${this.search.documentType}`
    }
  },
  data () {
    return {
      loading: true,
      search: new Search(Search.actions.GRID)
        .useInfiniteScroll()
        .setScope(this.scope)
        .chain(s => s.onceComplete(async () => {
          await this.$waitFor(() => this.$refs.list)
          this.loading = false
        }))
    }
  },
  watch: {
    includedDocumentTypes: {
      async handler (documentTypes) {
        this.search.chain(s => s.searchedDocumentTypes.include(documentTypes)).execute()
      },
      immediate: true
    },
    selectedDocumentType: {
      async handler (documentType) {
        this.search
          .setDocumentType(documentType)
          .setColumnSet(this.columnSets[documentType], true)
          .setSortSet(this.sortSets[documentType], true)
          .execute()
      },
      immediate: true
    }
  },
  props: {
    includedDocumentTypes: {
      required: true,
      type: Array
    },
    scope: {
      default: () => Search.typeOfScope.global(),
      type: Search.typeOfScope
    },
    selectedDocumentType: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

>>> .fade-transition-enter-active
  transition-delay 0.3s !important

>>> .v-virtual-scroll__item:not(:last-of-type)
  border-bottom solid 1px #B3B3B3

>>> .v-list-item__content
  padding 8px 0 !important
</style>
